import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Header from '../styles/Header'

export default function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404: Página não encontrada" />
      <Header as="h1" content="Página não encontrada" />
    </Layout>
  )
}
