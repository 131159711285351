import React from 'react'
import PropTypes from 'prop-types'

const Header = ({ as: Tag, content, textAlign, children }) => {
  return <Tag style={{ textAlign }}>{content || children}</Tag>
}

Header.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  content: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right'])
}

Header.defaultProps = {
  as: 'h1'
}

export default Header
